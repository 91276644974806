@import "tailwindcss";

h2 {
    @apply text-2xl font-bold text-gray-800 mt-6;
  }
  
  h3 {
    @apply text-xl font-semibold text-gray-700 mt-4;
  }

  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');
  body {
    font-family: 'Poppins', sans-serif;
  }

  /* Cibler toutes les <ul> dans ton contenu */
article ul, article ol {
  list-style-type: disc;
  padding-left: 1.5rem;  /* padding pour la marge gauche */
  margin-bottom: 0.5rem; /* espacement entre les éléments */
  margin-top: 0.5rem;
}

article ul li , article ol li{
  margin-bottom: 0.5rem;  /* espacement entre chaque <li> */
}

article p{
  font-weight: 300;
  margin-top:10px;
}

.amazon {
  margin-top:20px;
  padding: 10px 20px;
  background-color: #ff9900;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
}